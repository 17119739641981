import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function Redirect({ to }: any) {
    const params = useParams();
    for (const key in params) {
        to = to.replace(`:${key}`, `${params[key]}`)
    }
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
}