import { Console } from "console";
import { useAppSelector } from "../../../store/hooks";
import { isEqual } from "lodash";

export function pf(number: number) {
  var decimals = 2;
  var pow = Math.pow(10, decimals);
  return Number(Math.round(number * pow) / pow).toFixed(decimals);
}

// Same function in app/Webpage.php if changes then change in here too
export function getPageLocaleUrl(page: any, languages: any) {
  // @ts-ignore

  let locale = "et";
  let language = page.language;
  if (language && Array.isArray(languages)) {
    // @ts-ignore

    if (page.language.language_id && languages.length > 0) {
      const updatedLocale = languages.filter(
        (x) => x.language?.id === page.language?.language_id
      )[0]?.language.shortname;
      locale = updatedLocale;
    }
  }

  return "/" + locale + "/" + (page.url !== undefined ? page.url : "");
}

type LogErrorProps = {
  name: string | any;
  message: string | any;
  stack: string | any;
  componentStack: string | any;
  href: string | any;
};
var logStack: any = [];
var logStackTimeout: any;
export function logErrorToBackend(data: LogErrorProps) {
  logStack.push(data);
  if (logStackTimeout) {
    clearTimeout(logStackTimeout);
  }
  logStackTimeout = setTimeout(() => {
    logErrorStackToBackend();
  }, 3000);
}
function logErrorStackToBackend() {
  if (logStack.length) {
    const data: any = {
      logStack: logStack,
    };
    logStack = [];
    // @ts-ignore
    window.axios
      .post("logError", data)
      .then((res: any) => {})
      .catch((error: any) => {
        console.log(error);
      });
  }
}

export const getUpdatedComponents = (
  initialStructure: { components: { [key: string]: any } },
  currentStructure: { components: { [key: string]: any } }
) => {
  const updated: { [key: string]: any }[] = [];

  Object.keys(currentStructure.components).forEach((key) => {
    const component = currentStructure.components[key];
    const initialComponent = initialStructure.components[key];

    if (!isEqual(initialComponent, component)) {
      updated.push(component);
    }
  });

  return updated;
};

export const getUpdatedWebpageComponents = (
  initialStructure: number[],
  currentStructure: number[]
) => {
  if (!Array.isArray(initialStructure) || !Array.isArray(currentStructure)) {
    console.error("Invalid input to getUpdatedWebpageComponents");
    return [];
  }

  // Check if there is any difference in the initialStructure and currentStructure
  if (!isEqual(initialStructure, currentStructure)) {
    console.log("WebpageComponents changed");
    return [...currentStructure];
  }

  return [];
};
