import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import { pf } from "../utilities/helpers";
import Delivery, { Machine } from "./Delivery";
import usePageLanguage from "../hooks/usePageLanguage";
import { MuiTelInput } from "mui-tel-input";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
  deliveryMethod: DeliveryMethod | null;
  deliveryMethods: DeliveryMethod[];
}

const languageTranslations = {
  et: {
    delivery_details: "TARNE ANDMED",
    choose_parcel_machine: "Vali pakiautomaat",
    buyer_is_reciever: "Ostja võtab kauba vastu",
    first_name: "Eesnimi",
    last_name: "Perenimi",
    phone: "Mobiil",
    email: "E-post",
    save_data: "Andmete salvestamiseks tuleb nõustuda ",
    data_usega: "kasutustingimustega",
    policy: "küpsiste- ja privaatsuspoliitikaga",
    agree_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
    address: "Aadress",
    postal_code: "Postiindeks",
    city: "Linn",
    date_required: "Tarne märkimine on kohustuslik! Palun vali tarneaeg.",
  },
  en: {
    delivery_details: "DELIVERY DETAILS",
    choose_parcel_machine: "Choose parcel machine",
    buyer_is_reciever: "Buyer accepts the delivery",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    save_data: "To save your data, you need to agree to our ",
    data_usega: "data use",
    policy: "cookies and privacy policy",
    agree_terms: "AGREE WITH TERMS AND SAVE",
    aadress: "Address",
    postal_code: "Postal code",
    city: "City",
    date_required: "Delivery date is required! Please select delivery date.",
  },
};

export default function CheckoutDeliveryModal({
  show,
  setShow,
  deliveryMethod,
  deliveryMethods,
}: Props) {
  const { orderDeliveryMethod, confirmDeliveryData, order, buyer } =
    useShoppingCart();



  let lang = usePageLanguage();

  var receiverEmail = "";
  var receiverFirstname = "";
  var receiverSurname = "";
  var receiverPhone = "";
  var address = "";
  var city = "";
  var postal_code = "";
  var buyerIsReciever = true;

  if (orderDeliveryMethod && orderDeliveryMethod.id) {
    receiverEmail = buyer.email;
    receiverFirstname = buyer.firstname;
    receiverSurname = buyer.surname;
    receiverPhone = buyer.phone;

    if (orderDeliveryMethod.receiverEmail) {
      receiverEmail = orderDeliveryMethod.receiverEmail;
      receiverFirstname = orderDeliveryMethod.receiverFirstname;
      receiverSurname = orderDeliveryMethod.receiverSurname;
      receiverPhone = orderDeliveryMethod.receiverPhone;
    }
    address = orderDeliveryMethod.address;
    city = orderDeliveryMethod.city;
    postal_code = orderDeliveryMethod.postal_code;
    buyerIsReciever = orderDeliveryMethod.buyer_is_receiver ? true : false;
  }

  const [parcelMachineLocation, setParcelMachineLocation] =
    useState<Machine | null>(null);

  var defaultValues = {
    receiverEmail: receiverEmail,
    receiverFirstname: receiverFirstname,
    receiverSurname: receiverSurname,
    receiverPhone: receiverPhone ? receiverPhone : "+372",
    address: address,
    city: city,
    postal_code: postal_code,
    buyerIsReciever: buyerIsReciever,
    deliveryMethod: deliveryMethod?.id,
    parcelMachineLocation: parcelMachineLocation,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors },
  } = useForm({
    mode: "all",
    values: defaultValues,
  });

  const watchbuyerIsReciever = watch("buyerIsReciever");

  const [isDateErrorVisible, setIsDateErrorVisible] = useState(false);

  const onError = (data: any) => {
    console.log(data);
  };

  var delivery: any = null;
  const [deliveryParcels, setDeliveryParcels] = useState([]);
  useEffect(() => {
    if (deliveryMethod?.getDeliveryLocationsUrl) {
      delivery = Delivery(
        deliveryMethod.getDeliveryLocationsUrl,
        deliveryMethod.type
      );
      // Get list of parcel machines
      delivery.getParcelMachines(function (res: any) {
        setDeliveryParcels(res);
      });
    }
  }, [deliveryMethod]);

  useEffect(() => {
    setParcelMachineLocation(
      deliveryParcels.find(
        (x: any) => x.machineLocation === orderDeliveryMethod?.parcelMachineName
      ) ?? null
    );
  }, [deliveryParcels, orderDeliveryMethod?.parcelMachineName]);

  const handleCalculateMinDate = () => {
    const currentCartItems = order.order_products;
    let currentCartItemsHighestDeliveryTime;
    let highestDeliveryTime;

    if (order.delivery_time && order.delivery_time !== null) {
      let orderDeliveryTime = new Date(order.delivery_time);
      orderDeliveryTime.setFullYear(new Date().getFullYear());

      const days = Math.ceil(
        (orderDeliveryTime.getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      );

      highestDeliveryTime = days;
    } else {
      currentCartItemsHighestDeliveryTime = currentCartItems.reduce((a, b) => {
        const aProduct = a.location_product ? a.location_product : a.product;
        const bProduct = b.location_product ? b.location_product : b.product;

        let aDays =
          aProduct.intoWarehouse > 0
            ? aProduct.delivery_time_with_ordering.days
            : aProduct.delivery_time?.days;
        let bDays =
          bProduct.intoWarehouse > 0
            ? bProduct.delivery_time_with_ordering.days
            : bProduct.delivery_time?.days;

        // Check if aDays or bDays is null, then set it to 0
        if (aDays === null || aDays === undefined) {
          aDays = 0;
        }
        if (bDays === null || bDays === undefined) {
          bDays = 0;
        }

        return aDays > bDays ? a : b;
      });

      highestDeliveryTime =
        currentCartItemsHighestDeliveryTime?.location_product.intoWarehouse > 0
          ? currentCartItemsHighestDeliveryTime.location_product
              .delivery_time_with_ordering.days
          : currentCartItemsHighestDeliveryTime.location_product.delivery_time
          ? currentCartItemsHighestDeliveryTime.location_product.delivery_time
              .days
          : 0;
    }

    const today = new Date();
    const weekday = today.getDay();
    const deliveryMethodWeekday = deliveryMethod?.delivery_method_timings.find(
      (x) => x.weekday === weekday
    )?.min_days;

    let date;

    if (deliveryMethodWeekday > highestDeliveryTime) {
      date = new Date(
        today.getTime() + deliveryMethodWeekday * 24 * 60 * 60 * 1000
      );
    } else {
      date = new Date(
        today.getTime() + highestDeliveryTime * 24 * 60 * 60 * 1000
      );
    }

    return date;
  };

  const handleCalculateMaxDate = () => {
    const minDate = handleCalculateMinDate();
    const today = new Date();
    const weekday = today.getDay();
    const deliveryMethodWeekday = deliveryMethod?.delivery_method_timings.find(
      (x) => {
        if (x.weekday === weekday) {
          return x;
        } else if (x.weekday === 7 && weekday === 0) {
          return x;
        }
      }
    )?.max_days;

    if (
      deliveryMethodWeekday === "0" ||
      !deliveryMethodWeekday ||
      deliveryMethodWeekday === ""
    ) {
      return undefined;
    }

    const maxDate = new Date(
      minDate.getTime() + deliveryMethodWeekday * 24 * 60 * 60 * 1000
    );

    return maxDate;
  };

  // Add 1 day to selectedDateValue

  const [selectedDateTimeOptions, setSelectedDateTimeOptions] = useState([]);

  const [selectedDateValue, setSelectedDateValue] = useState<Date>(
    handleCalculateMinDate()
  );

  const [selectedDateTime, setSelectedDateTime] = useState("");

  const handleDateChange = (value) => {
    setIsDateErrorVisible(false);
    setSelectedDateValue(value);
    setSelectedDateTime("");
  };

  const handleTimeChange = (value) => {
    setIsDateErrorVisible(false);
    setSelectedDateTime(value);
  };

  useEffect(() => {
    const weekday = selectedDateValue?.getDay();

    const deliveryMethodValue = deliveryMethod?.delivery_method_timings.find(
      (x) => {
        if (x.weekday === weekday) {
          return x;
        } else if (x.weekday === 7 && weekday === 0) {
          return x;
        }
      }
    );

    let startTime = deliveryMethodValue?.start;
    let endTime = deliveryMethodValue?.end;
    let interval = deliveryMethodValue?.interval;

    //

    const generateTimeSlots = (startTime, endTime, interval) => {
      let timeSlots = [] as any;

      let start = new Date(`1970-01-01T${startTime}`);
      const end = new Date(`1970-01-01T${endTime}`);

      if (interval === 0 || interval === "" || interval === "0") {
        timeSlots.push(`${startTime} - ${endTime}`);
        return timeSlots;
      }

      while (start <= end) {
        const formattedTime = start.toTimeString().slice(0, 5);

        timeSlots.push(formattedTime as any);

        start = new Date(start.getTime() + interval * 60000);
      }

      return timeSlots;
    };

    const timeSlots = generateTimeSlots(startTime, endTime, interval);
    setSelectedDateTimeOptions(timeSlots);
  }, [selectedDateValue]);



  const onSubmit = (data: any) => {


    if (
      (!selectedDateValue || !selectedDateTime) &&
      deliveryMethod &&
      deliveryMethod.timing_required === 1
    ) {
      setIsDateErrorVisible(true);
      return;
    }

    // Check if selectedDateTime is range (for example, 12:00 - 19:00), if yes, then remove the range and only get the start time

    if (parcelMachineLocation) {
      let date = new Date(selectedDateValue);

      let selectedDateTimeUpdate = selectedDateTime;

      if (selectedDateTime && selectedDateTime.includes("-")) {
        selectedDateTimeUpdate = selectedDateTime.split("-")[0];
      }

      // Change time to selectedDateTime

      date.setHours(Number(selectedDateTimeUpdate.split(":")[0]));
      date.setMinutes(Number(selectedDateTimeUpdate.split(":")[1]));
      const pad = (num) => (num < 10 ? "0" : "") + num;
      // Convert the date to iso string, but dont convert the time to Z or UTC+0 time
      const isoString = `${date.getFullYear()}-${pad(
        date.getMonth() + 1
      )}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
        date.getMinutes()
      )}:${pad(date.getSeconds())}`;

      // Convert the date to iso string, but dont convert the time to Z or UTC+0 time

      confirmDeliveryData({
        ...data,
        address: parcelMachineLocation.address,
        city: parcelMachineLocation.city,
        postal_code: parcelMachineLocation.zipCode,
        parcelDescription: parcelMachineLocation.description,
        requested_delivery_at: isoString,
      });
    } else {
      let date = new Date(selectedDateValue);

      let selectedDateTimeUpdate = selectedDateTime;

      if (selectedDateTime && selectedDateTime.includes("-")) {
        selectedDateTimeUpdate = selectedDateTime.split("-")[0];
      }

      date.setHours(Number(selectedDateTimeUpdate.split(":")[0]));
      date.setMinutes(Number(selectedDateTimeUpdate.split(":")[1]));
      const pad = (num) => (num < 10 ? "0" : "") + num;
      // Convert the date to iso string, but dont convert the time to Z or UTC+0 time
      const isoString = `${date.getFullYear()}-${pad(
        date.getMonth() + 1
      )}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
        date.getMinutes()
      )}:${pad(date.getSeconds())}`;

     

      confirmDeliveryData({
        ...data,
        requested_delivery_at: isoString,
      });
    }
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        id="checkoutDeliveryMethodModal"
        contentClassName="modal-600"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="badge badge-square badge-primary me-3">3</span>
            <span className="fw-bold">
              {languageTranslations[lang]?.delivery_details}
            </span>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Modal.Body>
            <div className="row">
              <div className="col-xs-12 selectedDeliveryMethodButtonContainer">
                <button
                  className={
                    "btn btn-primary-outline text-left checkoutDeliveryButton" /*+ (deliveryMethod?.pickup ? ' margin-bottom-30' : '')*/
                  }
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {deliveryMethod?.locale_translations[lang]?.name}
                  <span className="pull-right">
                    {" "}
                    {pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;
                  </span>
                </button>
              </div>
              {deliveryMethod?.isParcelMachine && (
                <div
                  id="form-delivery-parcelMachine"
                  className="col-md-12 padding-top-15"
                >
                  <FormControl fullWidth>
                    <Controller
                      name="parcelMachineLocation"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          onChange={(e, parcelMachineLocation) => {
                            setParcelMachineLocation(parcelMachineLocation);
                            field.onChange(e, parcelMachineLocation);
                          }}
                          options={deliveryParcels}
                          value={parcelMachineLocation}
                          getOptionLabel={(parcel: any) =>
                            parcel.machineLocation
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.machineLocation == value.machineLocation
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder={
                                languageTranslations[lang]
                                  ?.choose_parcel_machine
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              )}
              {!(deliveryMethod as any).pickup &&
                !(deliveryMethod as any).isParcelMachine && (
                  <div id="form-delivery-address" className="padding-top-15">
                    <div className="col-md-12">
                      <Controller
                        name="address"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            autoFocus
                            label={languageTranslations[lang]?.address}
                            variant="standard"
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref}
                            error={errors.address?.type == "required"}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Controller
                        name="city"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label={languageTranslations[lang]?.city}
                            variant="standard"
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref}
                            error={errors.city?.type == "required"}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Controller
                        name="postal_code"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label={languageTranslations[lang]?.postal_code}
                            variant="standard"
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref}
                            error={errors.postal_code?.type == "required"}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
            </div>

            {isDateErrorVisible && (
              <div
                className="row col-xs-12"
                style={{
                  display: "flex",
                  color: "red",
                  fontWeight: "400",
                  marginTop: "20px",
                  marginBottom: "0px",
                  fontSize: "15px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {languageTranslations[lang]?.date_required}
              </div>
            )}

            {deliveryMethod &&
              deliveryMethod.delivery_method_timings &&
              deliveryMethod.delivery_method_timings.length > 0 && (
                <div
                  className="delivery_method_timings_container"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0px",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Calendar
                      minDate={handleCalculateMinDate()}
                      maxDate={handleCalculateMaxDate()}
                      locale="et"
                      tileDisabled={({ date, view }) => {
                        const dateWeekday = date.getDay();
                        const deliveryMethodWeekday =
                          deliveryMethod?.delivery_method_timings.find(
                            (d: any) => d.weekday === dateWeekday
                          );

                        if (!deliveryMethodWeekday) {
                          return true;
                        } else if (
                          deliveryMethodWeekday &&
                          deliveryMethodWeekday.active === 1
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      className={"deliveryPickupButtonCalendar"}
                      value={selectedDateValue}
                      onChange={(e) => {
                        handleDateChange(e);
                      }}
                    />
                  </div>
                  <div
                    className="deliveryPickupButtonTimeContainer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "9px",
                      maxHeight: "300px",
                      overflow: "scroll",
                    }}
                  >
                    {selectedDateTimeOptions?.map((d: any) => (
                      <button
                        className={
                          "deliveryPickupButtonTime" +
                          (d === selectedDateTime
                            ? " deliveryPickupButtonTime-active"
                            : "")
                        }
                        value={d}
                        key={d}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleTimeChange(d);
                        }}
                      >
                        {d}
                      </button>
                    ))}
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-xs-12">
                <Controller
                  name="buyerIsReciever"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "var(--shop-theme-color)" }}
                          classes={{ root: "custom-checkbox-root" }}
                          value={field.value}
                          checked={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                        />
                      }
                      label={languageTranslations[lang]?.buyer_is_reciever}
                    />
                  )}
                />
              </div>
            </div>

            {!watchbuyerIsReciever && (
              <div className="row" id="buyerIsNotRecieverContainer">
                <div className="col-md-6">
                  <Controller
                    name="receiverFirstname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{
                          color: "var(--shop-theme-color)",
                          fontSize: "14px",
                        }}
                        fullWidth
                        label={languageTranslations[lang]?.first_name}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.receiverFirstname?.type == "required"}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="receiverSurname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ fontSize: "14px" }}
                        fullWidth
                        label={languageTranslations[lang]?.last_name}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.receiverSurname?.type == "required"}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="receiverEmail"
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        style={{ fontSize: "14px" }}
                        fullWidth
                        sx={
                          // Add margin 0 to muiInput-root
                          {
                            "& .MuiInputBase-root": {
                              marginBottom: 0,
                            },
                          }
                        }
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "14px",
                          },
                        }}
                        label={languageTranslations[lang]?.email}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.receiverEmail?.type == "required"}
                        helperText={
                          errors.receiverEmail
                            ? errors.receiverEmail.message
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="receiverPhone"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTelInput
                        className=""
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang]?.phone}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.receiverPhone?.type == "required"}
                      />

                      // <TextField
                      //   fullWidth
                      //   style={{ fontSize: "14px" }}
                      //   label={languageTranslations[lang]?.phone}
                      //   variant="standard"
                      //   value={field.value}
                      //   onChange={field.onChange}
                      //   inputRef={field.ref}
                      //   error={errors.receiverPhone?.type == "required"}
                      // />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div
                className="col-xs-12 description"
                style={{ fontSize: "15px" }}
              >
                {deliveryMethod?.locale_translations[lang]?.description}
              </div>
            </div>

            <div className="mt-2 links-green">
              {languageTranslations[lang]?.save_data}
              <a href="/terms" target="_blank" className="color-primary">
                {languageTranslations[lang]?.data_usega}
              </a>
              ,{" "}
              <a href="/privacy" target="_blank" className="color-primary">
                {languageTranslations[lang]?.policy}
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="">
            <button
              type="submit"
              style={{
                color: "#fff",
                fontWeight: "600",
              }}
              className="btn  w-100 shop-controlled-border-radius shop-controlled-color"
            >
              {languageTranslations[lang]?.agree_terms}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
