import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage3Avaleht from "./pages/Webpage3Avaleht";
import Webpage16Isikuandmete_t____tlemine_kandideerimisel from "./pages/Webpage16Isikuandmete_t____tlemine_kandideerimisel";
import Webpage17T____tamine_IT_Projektis from "./pages/Webpage17T____tamine_IT_Projektis";
import Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt from "./pages/Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTc2NTk4MjgzZjk1ZDRmOTM0MjQzODk1NzgyYmNlOTBkODViZGZlZWYxY2RmZjJkODI4YzZkYWJhYjMxNzAxYjA4MmM4MjRiY2MzZjYyMzEiLCJpYXQiOjE3MzAxNDY1NTYuNjAwOTg5LCJuYmYiOjE3MzAxNDY1NTYuNjAwOTk5LCJleHAiOjE3MzAyMzI5NTYuNTk0OTcyLCJzdWIiOiI4Iiwic2NvcGVzIjpbXX0.GPkYZSffxC3w894R9Jsri40I5HERBAbFk14Yl_9wWxkgsSJA4A2lswlsftjHnQ_iSRx9t9uQnsF2iKKYxzCBHk4WJjKF0nsrqBhA-kvelQfhF-kZkX7NC1X0tWSNBYebSdlV3KM14do86dtmsj_-y1tfyb0vin172WeNqcVd_I2nJuXKfZwX9d7Gm3830gQuom8eDEbSwgpJz2hVEfN2VoGmZphU6a1QGaBionLz0228Gw2qOKejqFRXuJFFSRHuc1w-1w4dg0g7NJsC8Eml3vXaFlwAFLurtZHJxTxYQGKWbvYHzM3c663225G4agSsvSqBd_wpqHsON16H92ToPO9MqwUJ2OSDlz9oCyPgu2ZfBBJLe_YG-XUWnimXVNhlBJdd3heXPaNUXhZplg81Z-NCClpxyXeq4rYFoUcf4hE32v3jH1ir6SABNmsj1-hI75uBWmD3HfCq3RcXAwrn_pmBl7Xt3WVeOSTVfMFGUxW_v5gDBi53wYtgDpQ8696acXPLhnCruUy42HP5uEOjaO5FEyRifh8Mty1R4avLUWuHKpFduYMGvrlQzgbIueut97M-w_3L7qPsajFUS2rjxOCwG-F43y2NVIkQSmx-4ob0GM809CF1_ESapeF6omLVGNDvURxglY2LGujojJMVKJCWNvOCG9IUGV1WfOlt5nc',
    'Access-api-key': "eyJpdiI6Inl3Y2JCMVZsNXAwL2c5QVI1UzB3QXc9PSIsInZhbHVlIjoiVWppWFIyOWs1d2dWOWlmTUttOXNuQT09IiwibWFjIjoiOGNmYmUwNWI5MjRiNzM2NWRiNTgxNTc4ZTJlY2Q1NDY1N2YwYTRjYjA4NDRkZGFkNGJkMDRkYWFjMmI2Mzg5NiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6ImNsSVJpRm1LSCtieFZUbGQ2QUpGdHc9PSIsInZhbHVlIjoibExNc1F0SERwYm5zck9vbnNUemo1Zz09IiwibWFjIjoiNDJiZjcyYmQ1YTgxNTdhOTc2NmNjOGU0YTcxMWZhMjYxMTdjMTFhMjNmYTgyNTYyMmYyODNkY2E4YjVjMzMyOCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-MXH2B4"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage3Avaleht />} exact />;
                                                                    <Route path="/et/tootamine-it-projektis/isikuandmed" element={<Webpage16Isikuandmete_t____tlemine_kandideerimisel />} exact />;
                                                                    <Route path="/et/tootamine-it-projektis" element={<Webpage17T____tamine_IT_Projektis />} exact />;
                                                                    <Route path="/et/tootamine-it-projektis/full-stack-developer" element={<Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt />} exact />;
                                                                
                                            <Route path="itprojekt.ee/et/isikuandmed" element={<Redirect to="itprojekt.ee/et/tootamine-it-projektis/isikuandmed" />} exact />;
                                                                <Route path="*" element={<Webpage3Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}