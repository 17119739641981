import React, { useEffect } from "react";
import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { NavItemComponent as NavItemComponent_ } from "./interfaces/base";
import { Link } from "react-router-dom";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";

import MegaMenuComponent from "./MegaMenuComponent";
import { EditorContent } from "@tiptap/react";
import { getPageLocaleUrl } from "./utilities/helpers";
import "./Nav.css";

const Li = styled("li")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

interface NavItemComponentProps {
  id: number;
  childElements?: any;
  isMobile?: boolean;
  mobileMenuOpen?: boolean;
  setMobileMenuOpen?: (open: boolean) => void;
}

export default function NavItemComponent({
  id,
  childElements,
  isMobile,
  mobileMenuOpen,
  setMobileMenuOpen,
}: NavItemComponentProps) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id] as NavItemComponent_
  );

  const isBuilder = document.getElementById("BuilderContent");
  let editor = useAppSelector((state) => state.builder?.editor?.editor);

  const attributes = component?.attributes;

  const childWebpageComponentRelations =
    component?.childWebpageComponentRelations;

  const editorId = useAppSelector((state) => state.textEditor?.id);

  const [styles, setStyle] = useState<React.CSSProperties>();
  const typographies = useAppSelector((state) => state.builder.typographies);

  const colors = useAppSelector((state) => state.builder.colors);
  const pages = useAppSelector((state) => state.builder.pages);
  const structure = useAppSelector((state) => state.builder.structure);

  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  const compTypography = typographies?.find(
    (x: any) => x.id === component?.attributes?.typography_id
  );

  const [megaMenuOpen, setMegaMenuOpen] = useState(false);

  useEffect(() => {
    if (compTypography) {
      setStyle({
        ...styles,
        ...{
          fontSize: compTypography.font_size + compTypography.font_size_unit,
          fontFamily: compTypography.font_family,
          fontStyle: compTypography.font_style,
          fontWeight: compTypography.font_weight,
          letterSpacing: compTypography.letter_spacing,
          lineHeight: compTypography.line_height,
          color: colors?.find((x: any) => x.id === compTypography.color_id)
            ?.color,
        },
      });
    }
  }, [typographies, attributes]);

  function generateLink(link: any) {
    let href;

    if (link.type === "Page" && link.pageId) {
      const page = pages.list.find((x) => x.id === link.pageId);

      if (page) {
        href = getPageLocaleUrl(page, languages);
      }
    } else if (
      attributes.link?.type === "Page" &&
      !attributes.link?.pageId &&
      attributes.link?.url
    ) {
      const page = pages.list.find((x) => x.url === attributes.link?.url);

      if (page) {
        href = getPageLocaleUrl(page, languages);
      }
    } else if (link.type === "Web_out") {
      if (!link.url.includes("http")) {
        href = `https://${link?.url}`;
      } else {
        href = link.url;
      }
    } else if (link.type === "Web") {
      href = link.url;
    } else if (link.type === "None") {
      href = "";
    } else if (link.type === "Anchor") {
      const anchorLink = link?.url?.startsWith("#") ? link.url : `#${link.url}`;

      href = anchorLink;
    } else if (link.type === "Phone") {
      if (link.url.startsWith("tel:")) {
        href = link.url;
      } else {
        href = `tel:${link.url}`;
      }
    } else if (link.type === "Email") {
      if (link.url.startsWith("mailto:")) {
        href = link.url;
      } else {
        href = `mailto:${link.url}`;
      }
    }

    return link.type === "Page" ? (
      <Link
        className="nav-link"
        key={link.text + link.type + href}
        to={`${href}`} // Avoid redirecting when editing
        target={
          link?.type === "Web" ||
          link?.type === "None" ||
          link?.type === "Anchor" ||
          link?.type === "Phone" ||
          link?.type === "Email"
            ? "_self"
            : link?.target
        }
        onClick={() => {
          console.log("click triggered");

          if (isMobile && mobileMenuOpen && setMobileMenuOpen) {
            setMobileMenuOpen(false);
          }
        }}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
        style={{
          ...styles,
          pointerEvents: isBuilder ? "none" : "all",
        }}
      ></Link>
    ) : (
      <a
        className="nav-link"
        key={link.text + link.type + href}
        href={href}
        target={
          link?.type === "Web" ||
          link?.type === "None" ||
          link?.type === "Anchor" ||
          link?.type === "Phone" ||
          link?.type === "Email"
            ? "_self"
            : link?.target
        }
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
        onClick={(e) => {
          console.log("click triggered");

          if (isBuilder) {
            e.preventDefault();
          }
          if (isMobile && mobileMenuOpen && setMobileMenuOpen) {
            setMobileMenuOpen(false);
          }
        }}
        style={{
          ...styles,
          zIndex: 2,
          pointerEvents: isBuilder ? "none" : "all",
        }}
      ></a>
    );
  }

  if (
    !attributes ||
    !attributes.link ||
    attributes === undefined ||
    !component
  ) {
    return <></>;
  }

  const classes = attributes ? attributes.classes : {};

  const handleMegaMenuOpen = (value: boolean) => {
    if (attributes.link.type == "MegaMenu") {
      setMegaMenuOpen(value);
    }
  };

  // If dropdown use div instead of a
  const tag = React.createElement(
    `${
      attributes.link.type == "Dropdown" || attributes.link.type == "MegaMenu"
        ? "div"
        : "a"
    }`,
    {
      className: "nav-link",
      dangerouslySetInnerHTML: { __html: attributes.text },
      contentEditable: editorId == id,
    }
  );

  // function generateMegaMenu() {
  //   if (attributes.link.type == "MegaMenu" && attributes.link.template_id) {
  //     const template = templates.find(
  //       (x) => x.id == attributes.link.template_id
  //     );

  //     if (template) {
  //       return (
  //         <MegaMenuComponent
  //           id={template.id}
  //           parentId={id}
  //           menuOpen={megaMenuOpen}
  //           setMegaMenuOpen={setMegaMenuOpen}
  //           leftPosition={attributes.link.left_position}
  //           topPosition={attributes.link.top_position}
  //           activateType={attributes.link.activate_type}
  //         />
  //       );
  //     }
  //   }
  // }

  // if (
  //   component.attributes.link.type === "MegaMenu" &&
  //   component.attributes.link.template_id
  // ) {

  //   if (component.childWebpageComponentRelations.length >= 1) {
  //     StructureAPI.addExistingComponent(pages.active!, {
  //       id: attributes.link.template_id!,
  //       parent_id: id ?? null,
  //       sort_order: 1,
  //     }).then(() => {
  //       StructureAPI.loadWebpageComponents(webpage_id!).then((x) => {
  //         setStructure(x);
  //       });
  //     });
  //   }
  // }

  return (
    <>
      <style>
        {`
      .nav-link p {
        margin-bottom: 0rem;
      }
    `}
      </style>

      <Li
        id={id.toString()}
        data-component-type="nav-item"
        className={
          "nav-item nav-link " +
          (classes ?? "") +
          (attributes && attributes.link && attributes.link.type === "Dropdown"
            ? " position-relative"
            : "") +
          " builder-component builder-component__nav-item" +
          (editorId === id ? " builder-component--active" : "")
        }
        data-style={{
          ...styles,
          zIndex: editorId === id ? 9 : "auto",
        }}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
          ...attributes.styles,
          position: "relative",
          pointerEvents: "visible",
        }}
        onMouseEnter={(e) => {
          if (isMobile) {
            return;
          }

          handleMegaMenuOpen(true);

          // setMegaMenuOpen(true);
        }}
        onMouseLeave={(e) => {
          if (isMobile) {
            return;
          }

          handleMegaMenuOpen(false);

          // setMegaMenuOpen(false);
        }}
        onClick={(e) => {
          if (isMobile) {
            return;
          }

          handleMegaMenuOpen(!megaMenuOpen);

          // setMegaMenuOpen(!megaMenuOpen);
        }}
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations.filter(
            (componentRelation) => {
              const childComponent =
                structure.components[componentRelation.child_id];
              if (childComponent && childComponent.type == "editor") {
                return true;
              }
              return false;
            }
          )}
        />

        {attributes &&
          attributes.link &&
          attributes.link.type !== "Dropdown" &&
          attributes.link.type !== "MegaMenu" &&
          editorId !== id &&
          generateLink({
            pageId: attributes.link.pageId,
            type: attributes.link.type,
            url: attributes.link.url,
            target: attributes.link.target,
            text: attributes.text,
          })}

        {isBuilder && editorId === id && editor && (
          <div>
            <EditorContent
              id={id + "_content"}
              content={editor.getHTML()}
              editor={editor}
            ></EditorContent>
          </div>
        )}

        {attributes &&
          attributes.link &&
          attributes.link.type === "Dropdown" &&
          attributes.links &&
          editorId !== id &&
          attributes.links.length >= 0 && <>{tag}</>}

        {attributes &&
          attributes.link &&
          attributes.link.type === "MegaMenu" &&
          editorId !== id && (
            <span
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              {tag}
            </span>
          )}

        {attributes &&
          attributes.link &&
          attributes.link.type === "MegaMenu" &&
          attributes.link.template_id !== null &&
          editorId !== id && (
            <>
              <MegaMenuComponent
                id={attributes.link.template_id}
                parentId={id}
                menuOpen={megaMenuOpen}
                setMegaMenuOpen={setMegaMenuOpen}
                leftPosition={attributes.link?.left_position}
                topPosition={attributes.link?.top_position}
                activateType={attributes.link?.activate_type}
              />
            </>
          )}

        {attributes &&
          attributes.link &&
          attributes.link.type === "Dropdown" &&
          attributes.links &&
          !isMobile &&
          attributes.links.length > 0 && (
            <>
              <div className="nav-subnav">
                {attributes.links && attributes.links.length > 0 && (
                  <>{attributes.links.map((item) => generateLink(item))}</>
                )}
              </div>
            </>
          )}

        {attributes &&
          attributes.link &&
          attributes.link.type === "Dropdown" &&
          attributes.links &&
          isMobile &&
          attributes.links.length > 0 && (
            <>
              <div
                style={{
                  width: "100%",
                  marginLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "15px",
                }}
              >
                {attributes.links.map((item) => generateLink(item))}
              </div>
            </>
          )}
      </Li>
    </>
  );
}
