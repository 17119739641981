import { memo } from "react";
import { useAppSelector } from "../../store/hooks";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import "./Default.css";
import React from "react";

declare var defaultComponents: any;

function ContentRenderWebpageComponent({ componentId }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[componentId]
  );

  const webpageComponents = useAppSelector(
    (state) => state.builder.structure.webpageComponents
  );

  const templates = useAppSelector((state) => state.builder.templates);

  const editor = useAppSelector((state) => state.builder.editor);

  if (editor && editor.template && componentId === editor.template?.id) {
    return <></>;
  }

  if (!component) {
    return <></>;
  }

  if (component.sort_order === -44) {
    return <></>;
  }

  if (component.sort_order === 0) {
    const template = templates.find(
      (x) => x.webpage_component_id === componentId
    );
    if (template) {
      return <></>;
    }


  }

  // Needed for templates
  if (
    component.sort_order === -44 &&
    component.type !== "header" &&
    webpageComponents.length > 2
  ) {
    return <></>;
  }

  if (!defaultComponents[component.type]) {
    return <></>;
  }
  const ComponentToRender = defaultComponents[component.type];

  // @ts-ignore
  return (
    <ErrorFallbackComponent
      key={component.id + "_errorBoundary_"}
      showHomeButton={true}
    >
      <ComponentToRender {...component} key={component.id} />
    </ErrorFallbackComponent>
  );
}

export default memo(ContentRenderWebpageComponent);
